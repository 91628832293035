import React,  {Alert,Component} from 'react';
import { Redirect  } from 'react-router-dom';


class Deconnexion extends Component{
    
    constructor(props) {
    super(props);

    this.state = {
  
      refreshfinish: '',

    };
        
  }


  componentWillMount() {

    
  }
    
    componentDidMount() {

      sessionStorage.setItem(
        'active',''
      );

      sessionStorage.setItem(
        'reloaded', false
      );

      sessionStorage.removeItem('active');

      sessionStorage.setItem(
        'refreshdata', 'no'
      );


        this.setState({ tokenexist: false})
      
        this.refreshdata();


    };

    refreshdata() {

      var refreshfinish = sessionStorage.getItem('refreshdata');

      if(refreshfinish === 'no'){      
        this.setState({ refreshfinish: 'yes'})
        window.location.reload(false);

      }

    }
    
  render() {

          return (
              
                <div className="d-flex justify-content-center" style={{marginTop:'250px'}}>

                    
                   {this.state.refreshfinish === 'yes' ?
                            

                            <Redirect push to="/"/>
                              

                            :

                            <Redirect push to="/"/>

                        }

                </div>
          );
        }
    
}

export default Deconnexion;
