import '../App.css';
import React,  {Alert,Component,PointerEvent} from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade,EffectCreative } from 'swiper';
import { Pagination, Navigation,Autoplay } from "swiper";
import { useRef, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import 'swiper/css/effect-fade';
import "swiper/css/effect-creative";
import { Nav,Navbar,Form,Button,Container,Row,Col,NavDropdown,Card,Modal} from 'react-bootstrap';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import MailIcon from '@material-ui/icons/Mail';
import CallIcon from '@material-ui/icons/Call';
import HouseIcon from '@material-ui/icons/House';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { BrowserRouter as Router, Switch, Route, Link,Redirect,HashRouter } from 'react-router-dom';
import Bounce from 'react-reveal/Bounce';
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import Carousel from 'react-bootstrap/Carousel';
import { BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import queryString from 'query-string';
import { Rating } from 'react-simple-star-rating'


class Accueil extends Component{
    
  constructor(props) {
  super(props);
    this.state = {
      loaddata : true,
      show: false,
      setShow: false,
      isModalVisible: false,
      isModalloderVisible : true,
      accueiltitre: '',
      accueilimage: '',
      touteslesarticles: [],
      touteslestours: [],
      listimage:[],
      idtourselect:0,
      title:'',
      description:'',
      roadmap:'',
      extensionfile:'',
      touteslesaccueil: [],
      touteslesgallery: [],
      gallery: [],
      allopinionuser:[],
      width: 0,
      height: 0,
      scrollpage:0,
      ratingvalue:0,
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
      
  }
  
  async componentDidMount() {

    {/*//liste des articles
    const response = await fetch('https://mada-phototours.com/wbs/listtours/alltours.php');
    const data = await response.json();
  this.setState({ touteslesarticles: data})*/} 
    
    //liste des accueils
    const responseaccueil = await fetch('https://exotic-madagascar.net/wbs/accueil/allaccueil.php');
    const dataaccueil = await responseaccueil.json();
    this.setState({ touteslesaccueil: dataaccueil})
    
    //liste des gallery
    const responsegallery= await fetch('https://exotic-madagascar.net/wbs/gallery/allgallery.php');
    const datagallery = await responsegallery.json();
    this.setState({ touteslesgallery: datagallery}) 
  
    this.refreshgallery(datagallery) 

    //liste des tours
    const responsetours = await fetch('https://exotic-madagascar.net/wbs/tours/alltours.php');
    const datatours = await responsetours.json();
    this.setState({ touteslestours: datatours}) 
    
    

    //liste opinion
    const allopinion = await fetch('https://exotic-madagascar.net/wbs/users/selectopinion.php');
    const dataopinion = await allopinion.json();
    if(JSON.stringify(dataopinion).replace(/\"/g, "") !== '0'){
      this.setState({ allopinionuser: dataopinion})
    }
    
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    window.addEventListener('scroll', this.updateWindowDimensions);
      
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    window.removeEventListener('scroll', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight, scrollpage: window.scrollY });
  }

  scrolltop(){
    window.scrollTo(0, 0)
  }

  refreshgallery(datagallery){
    
    datagallery.forEach((item)=>{
      this.state.gallery.push({ original: process.env.PUBLIC_URL + './images/gallery/' + item.idgallery + '.' + item.extensionimage, thumbnail: process.env.PUBLIC_URL + './images/gallery/' + item.idgallery + '.' + item.extensionimage});
    })

    /*for (let i = 1; i < 21; i++) {

      if(i<10){
        this.state.gallery.push({ original: process.env.PUBLIC_URL + './images/gallerie/0' + i + '.jpg', thumbnail: process.env.PUBLIC_URL + './images/gallerie/0' + i + '.jpg'});
      }else{
        this.state.gallery.push({ original: process.env.PUBLIC_URL + './images/gallerie/' + i + '.jpg', thumbnail: process.env.PUBLIC_URL + './images/gallerie/' + i + '.jpg'});
      }
      
    }*/

  }

  reservation = (typetours) => { 

    window.location.href = "mailto:contact@exotic-madagascar.net?Subject=Booking to " + typetours

  }

  async selecttours(idtours,tourstitle,toursdescription,toursroadmap,extensionfile){

    this.setState({
      idtourselect: idtours,
      title:tourstitle,
      description:toursdescription,
      roadmap:toursroadmap,
      extensionimg: extensionfile,
      isModalVisible:true
    })
  
    const response = await fetch('https://exotic-madagascar.net/wbs/tours/gallerytours/selectimagetours.php',{                  
          method:'post',
          headers: {'Content-Type':'application/x-www-form-urlencoded'},
          body: queryString.stringify({
            idtrs:idtours
          })});
    const data = await response.json();
  
    this.setState({
      listimage:data,
    })
  
  }

  

  handlePointerMove = (value, index) => {
    this.setState({ ratingvalue: value}) 
  }

  render() {

      const onPointerMove = (value,index) => this.handlePointerMove(value, index)

        return (
            
              <div>
                
                <div id="accueil">

                <Swiper

                    direction={"horizontal"}
                    spaceBetween={0}
                    centeredSlides={true}
                    autoplay={{
                      delay: 4000,
                      disableOnInteraction: false,
                    }}
                    effect="fade"
                    pagination={{
                      clickable: true,
                    }}
                    loop={true}
                    modules={[Autoplay, Pagination, Navigation,EffectFade]}
                    className="swiper"
                    mousewheel={false}
                    Mousewheel={false}
                    mousewheelControl={false}
                    keyboardControl={true}
                    Scrollbar={false}
                    noSwiping={true}
                    onlyExternal={true}
                    allowTouchMove={false}
                    speed={4000}
                    >

                    {this.state.touteslesaccueil.map((acc) => {
                        return (

                            <div style={{margin:"20px"}}>

                              
                                  <SwiperSlide>
                                    <Fade delay={7000}>
                                    <div style={{backgroundImage: "url(" + process.env.PUBLIC_URL + './images/accueil/' + acc.idaccueil + '.' + acc.extensionimage + ")", backgroundPosition: 'center',  backgroundSize: isBrowser ? 'cover': 'contain',  backgroundRepeat: 'no-repeat', width:'100%', height: isBrowser? '100vh':'23rem'}}>
                                      <div style={{marginTop:isBrowser?'20%':'30%', marginLeft:'5%'}}>

                                        <Fade delay={1000}>
                                          <p style={{fontSize: isBrowser?'1.5rem':'1.2rem', color: 'white'}} className="textombrenoir">{acc.titre}</p>
                                        </Fade>

                                        <Fade delay={1000}>
                                          <p style={{fontSize: isBrowser?'3rem':'1.5rem', color: 'white', fontWeight:'bold'}} className="textombrenoir">{acc.description}</p>
                                        </Fade>

                                      </div>
                                      

                                    </div>
                                    </Fade>
                                    </SwiperSlide>
                                
                            </div>

                        );
                    })}

                    </Swiper> 
                </div>

                <div>

                <BrowserView>
                <Modal
                        size='xl'
                        show={this.state.isModalVisible}
                        onHide={ () => this.setState({isModalVisible:false,idtourselect: 0, title:'', description:'', roadmap:'', extensionimg: '',listimage:[]})}
                        dialogClassName="modal-dialog"
                        aria-labelledby="example-custom-modal-styling-title" centered
                    >
                    
                    <Modal.Body>
                        
                        <div className="d-flex flex-row">

                          {this.state.listimage.length>0 ?

                              <Carousel >

                              {this.state.listimage.map((lst) => {

                              return (

                                    <Carousel.Item key={lst.idimagestours} interval={3000}>
                                  
                                      <img
                                        style={{width:"500px",height:"500px", marginTop:"0px", borderRadius:"10px"}}
                                        src={process.env.PUBLIC_URL + './images/tours/gallerytours/' + lst.idimagestours + '.' + lst.extensionimage}
                                      />
                                                                                  
                                    </Carousel.Item>

                                );
                              })} 

                              </Carousel>

                            :

                            <img
                                style={{width:"500px",height:"500px", marginTop:"0px", borderRadius:"10px"}}
                                src={process.env.PUBLIC_URL + './images/tours/' + this.state.idtourselect + '.' + this.state.extensionimg}
                              />

                          }
                            
                            
                                <div className="d-flex flex-column" style={{margin:"20px"}}>
                                    
                                    <div className="d-flex flex-column">
                                        <p style={{fontWeight:"bold", textAlign:'center'}}>{this.state.title}&nbsp;</p>
                                        <p>{this.state.description}</p>
                                    </div>
                                    
                                <div>
                                    <div className="d-flex flex-column" style={{overflow:"auto", height:'260px', width:'600px',}}> 
                                      <div dangerouslySetInnerHTML={{ __html: this.state.roadmap }}></div>           
                                    </div>
                                    
                                </div>
                                
                                {/*<div className="d-flex flex-row" style={{marginTop:"20px", marginBottom:"20px"}}>
                                        <div style={{fontWeight:"bold"}}>Price :&nbsp;</div>
                                        <div>500 € </div>
                                </div>*/}
                                                                    
                                <div className='row text-white d-flex align-items-center'>
                                    <div style={{marginBottom:"-20px", position:"fixed"}}>
                                        <Button className="btn "style={{ marginLeft:"16px"}} onClick={() => this.setState({isModalVisible:false,idtourselect: 0, title:'', description:'',  roadmap:'',  extensionimg: '',listimage:[]})} variant="primary">Close</Button>
                                        <Button className="btn btn-warning " style={{ marginLeft:"360px"}} onClick={() => this.reservation(this.state.title)} variant="primary">Book it</Button>
                                    </div>
                                    
                                </div>

                                </div>

                        </div>    

                    </Modal.Body>
                    </Modal>
                </BrowserView>

                <MobileView >

                <Modal
                        
                      show={this.state.isModalVisible}
                      onHide={ () => this.setState({isModalVisible:false,idtourselect: 0, title:'', description:'',  roadmap:'',  extensionimg: '', listimage:[]})}
                      dialogClassName="modal-dialog-mobile"
                      aria-labelledby="example-custom-modal-styling-title" centered
                    >
                    
                    <Modal.Body>

                        <div className="modal-infotype">

                          {this.state.listimage.length>0 ?

                            <Carousel >

                            {this.state.listimage.map((lst) => {

                            return (

                                  <Carousel.Item key={lst.idimagestours} interval={3000}>
                                
                                    <img
                                      className="d-block w-100" style={{width:"100%",height:"40vh", marginTop:"0px", borderRadius:"10px"}}
                                      src={process.env.PUBLIC_URL + './images/tours/gallerytours/' + lst.idimagestours + '.' + lst.extensionimage}
                                    />
                                                                                
                                  </Carousel.Item>

                              );
                            })} 

                            </Carousel>

                            :

                            <img
                              style={{width:"100%",height:"40vh", marginTop:"0px", borderRadius:"10px"}}
                              src={process.env.PUBLIC_URL + './images/tours/' + this.state.idtourselect + '.' + this.state.extensionimg}
                            />

                            }

                                <div style={{margin:"20px",overflow:"auto", height:'220px', width:"40vh"}}>
                                    
                                    <div className="d-flex flex-column">
                                        <p style={{fontWeight:"bold", textAlign:'center'}}>{this.state.title}&nbsp;</p>
                                        
                                    </div>
                                    <p>{this.state.description}</p>
                                  
                                    <div>
                                      
                                      <div dangerouslySetInnerHTML={{ __html: this.state.roadmap }}></div> 
                                        
                                    </div>
                                
                                    {/*<div className="d-flex flex-row" style={{marginTop:"20px", marginBottom:"20px"}}>
                                        <div style={{fontWeight:"bold"}}>Price :&nbsp;</div>
                                        <div>500 € </div>    
                                    </div>*/}

                                </div>

                                <Button className="btn float-start" onClick={() => this.setState({isModalVisible:false,idtourselect: 0, title:'', description:'',  roadmap:'',  extensionimg: '', listimage:[]})} variant="primary">Close</Button>
                                <Button className="btn btn-warning float-end"  onClick={() => this.reservation(this.state.title)} variant="primary">Book it</Button>

                        </div>    

                    </Modal.Body>
                    </Modal>

                </MobileView>
                                        
                   
                </div>

                    
                    {this.state.scrollpage>200 ?

                        <Zoom>
                          <button className="bnttop" onClick={() => this.scrolltop()} style={{width:'50px', height:'50px', marginBottom:"70px",padding:"0%"}}><span><KeyboardDoubleArrowUpIcon /></span></button>
                        </Zoom>

                        :

                        null
                    
                    }
                    
          
                    <div id="tours" >
                        
                        <Fade bottom>
                          <div className='align-items-center'>                         
                              <p style={{color:"black", fontWeight:"bold", fontSize:"2em", textAlign:'center', marginTop:'20px'}}>Travel Tours</p>

                          </div>
                        </Fade>
                        

                        <BrowserView>
                          <div className='listedata'>
                            {this.state.touteslestours.map((tours) => {
                              return (

                                  <div >

      
                                      <Fade bottom>
                                        <div key={tours.idtours} className="cardlist" style={{}}>
                                          <div className="row">

                                            <div className="col">

                                                <img variant="top" src={process.env.PUBLIC_URL + './images/tours/' + tours.idtours + '.jpg'} style={{width:"24rem", height:"16rem", marginTop:"1rem", marginLeft:"1rem",resizeMode:"contain"}} className="rounded float-left" alt='Mada Photo Tours'/>

                                            </div>

                                            <div className="col" style={{width:"25rem", height:"18rem"}}>

                                                    <p style={{marginTop:"2rem", fontSize:"1.5rem", fontWeight:"bold", textAlign:"center"}}>{tours.titletours}</p>
                                                    <p style={{fontSize:'1rem', margin:"1rem"}}>{tours.descriptiontours}</p>
                                                    
                                                    <div className='align-items-center'>
                                                        
                                                        <button className="buttondetails" style={{width:'100px', margin:"1rem"}} onClick={() => this.selecttours(tours.idtours,tours.titletours,tours.descriptiontours,tours.roadmaptours,tours.extensionimage)} >ABOUT</button>
                                                        
                                                    </div>
                                            </div>

                                          </div>
                                        </div>
                                      </Fade>
          
                                                                    
                                  </div>

                                );
                            })}

                          </div>

                        </BrowserView>

                        <MobileView>
                          <div className='listedatamobile justify-content-center'>
                            {this.state.touteslestours.map((tours) => {
                              return (

                                  <div >

      
                                        <Fade bottom>
                                          <div key={tours.idtours} className="cardlistmobile" >
                                            <div className="column">

                                            

                                                  <img variant="top" src={process.env.PUBLIC_URL + './images/tours/' + tours.idtours + '.jpg'} style={{width:"90%", height:"16rem", margin:"1rem",resizeMode:"contain", borderRadius:"10px"}}  alt='Mada Photo Tours'/>

                                              

                                              <div style={{width:"100%", height:"18rem"}}>

                                                      <p style={{marginTop:"1rem", fontSize:"1.5rem", fontWeight:"bold", textAlign:"center"}}>{tours.titletours}</p>
                                                      <p style={{fontSize:'1rem', margin:"1rem"}}>{tours.descriptiontours}</p>
                                                      
                                                      <div className='align-items-center'>
                                                          
                                                          <button className="buttondetails" style={{width:'100px', margin:"1rem"}} onClick={() => this.selecttours(tours.idtours,tours.titletours,tours.descriptiontours,tours.roadmaptours,tours.extensionimage)} >ABOUT</button>
                                                          
                                                      </div>
                                              </div>

                                            </div>
                                          </div>
                                        </Fade>
          
                                                                    
                                  </div>

                                );
                            })}
                          </div>
                        </MobileView>
                    </div> 
                    
                    <div id="gallery">

                    <Fade bottom>
                          <div className='align-items-center'>                         
                              <p style={{color:"black", fontWeight:"bold", fontSize:"2em", textAlign:'center', marginTop:'20px'}}>Gallery</p>

                          </div>
                        </Fade>
                      <ImageGallery
                            items={this.state.gallery}
                            autoPlay={true}
                            slideDuration={1000}                                    
                            slideInterval={5000}
                            showBullets={true}
                            showIndex={true}
                            showThumbnails={true}
                            lazyLoad={true}
                            showPlayButton={false}
                            thumbnailClass ={{backgroundColor:"white"}}
                           
                        />

                    </div>

                    {this.state.allopinionuser.length>0 ?

                      <div id="opinion" style={{marginBottom:"100px"}}>
                                              
                      <Fade bottom>
                        <div className='align-items-center'>                         
                            <p style={{color:"black", fontWeight:"bold", fontSize:"2em", textAlign:'center'}}>Opinion</p>

                        </div>
                      </Fade>


                      <BrowserView>
                        <div className='listedata'>
                          {this.state.allopinionuser.map((opinion) => {
                            return (

                                <div >


                                    <Fade bottom>

                                      <div key={opinion.idutilisateur} className="cardlistopinion" style={{padding:'20px', width:'500px', height:'auto'}}>
                                        <p style={{fontSize:'1.5rem',fontWeight:'bold'}}>{opinion.prenomutilisateur} {opinion.nomutilisateur}</p>

                                        <div className="form-group" style={{margin:'10px'}}>
                                          <Rating
                                            onPointerMove={onPointerMove}
                                            initialValue={opinion.avis}
                                            readonly={true}
                                          />
                                        </div>

                                        <p>{opinion.descriptionavis}</p>

                                      </div>

                                    </Fade>
                                                
                                </div>

                              );
                          })}

                        </div>

                      </BrowserView>

                      <MobileView>
                        <div className='listedatamobile justify-content-center'>
                          {this.state.allopinionuser.map((opinion) => {
                            return (

                                <div >


                                    <Fade bottom>

                                      <div key={opinion.idutilisateur} className="cardlistopinion" style={{padding:'20px', width:'500px', height:'auto'}}>
                                        <p style={{fontSize:'1.5rem',fontWeight:'bold'}}>{opinion.prenomutilisateur} {opinion.nomutilisateur}</p>

                                        <div className="form-group" style={{margin:'10px'}}>
                                          <Rating
                                            onPointerMove={onPointerMove}
                                            initialValue={opinion.avis}
                                            readonly={true}
                                          />
                                        </div>

                                        <p>{opinion.descriptionavis}</p>

                                      </div>
                                      
                                    </Fade>
                                                
                                </div>

                              );
                          })}
                        </div>
                      </MobileView>
                      </div> 

                      :

                      null

                      }

                    <BrowserView>

                      <div id="contact" className="justify-content-center footer">

                        <div className="container">

                        <div className="container">
                          <div className="row">
                            <Fade left>
                            <div className="col">
                              <p style={{color:"white", fontWeight:"bold", fontSize:"1.5em", marginTop:'20px'}}>Contact</p>
                              
                              {/*
                              <div className="contactlist'">  
                                  <a href="tel:+000000000" target="_blank"><CallIcon style={{fontSize:'30' , color: '#01b3a7', marginTop:10,marginBottom:10, marginRight:10}}/></a>
                                  <a href="tel:+000000000" target="_blank"></a>
                              </div>
                              */}

                              <div className="contactlist'">  
                                  <a href="mailto:contact@exotic-madagascar.net" target="_blank"><MailIcon style={{fontSize:'30' , color: '#01b3a7', marginTop:10,marginBottom:10, marginRight:10}}/></a>
                                  <a href="mailto:contact@exotic-madagascar.net" target="_blank">contact@exotic-madagascar.net</a>
                              </div>
                              
                              {/*
                              <div className="contactlist'">  
                                  <a href="mailto:armeltourguide@gmail.com" target="_blank" rel="noopener noreferrer"><MailIcon style={{fontSize:'30' , color: '#01b3a7', marginTop:10,marginBottom:10, marginRight:10}}/></a>
                                  <a href="mailto:armeltourguide@gmail.com" target="_blank" rel="noopener noreferrer">Mail to the guide</a>
                              </div>
                              */}

                              <div className="contactlist'">  
                                  <a href="https://facebook.com/ArmelMadagascar" target="_blank"><SendIcon style={{fontSize:'30' , color: '#01b3a7', marginTop:10,marginBottom:10, marginRight:10}}/></a>
                                  <a href="tel:+000000000" target="_blank">Antananarivo 101</a>
                              </div>

                            </div>
                            </Fade>

                            <Fade right>
                            <div className="col">
                              <p style={{color:"white", fontWeight:"bold", fontSize:"1.5em", marginTop:'20px'}}>Support</p>
                              <div className="contactlist'">  
                                  <a href="https://facebook.com/ArmelMadagascar" target="_blank"><FacebookIcon style={{fontSize:'30' , color: '#01b3a7', marginTop:10,marginBottom:10, marginRight:10}}/></a>
                                  <a href="https://facebook.com/ArmelMadagascar" target="_blank">Exotic Madagascar</a>
                              </div>

                              <div className="contactlist'">  
                                  <a href="https://linkedin.com/in/exoticmadagascar" target="_blank"><LinkedInIcon style={{fontSize:'30' , color: '#01b3a7', marginTop:10,marginBottom:10, marginRight:10}}/></a>
                                  <a href="https://linkedin.com/in/exoticmadagascar" target="_blank">Exotic Madagascar</a>
                              </div>

                              <div className="contactlist'">  
                                  <a href="https://youtube.com/user/exoticmadagascar" target="_blank"><YouTubeIcon style={{fontSize:'30' , color: '#01b3a7', marginTop:10,marginBottom:10, marginRight:10}}/></a>
                                  <a href="https://youtube.com/user/exoticmadagascar" target="_blank">Exotic Madagascar</a>
                              </div>
                            </div>
                            </Fade>
                            
                          </div>

                        </div>

                          <Zoom>

                            <div className="row">
                                <div className="text-center">
                                    
                                    <div className="py-1">
                                        <a href="https://web.facebook.com/ArmelMadagascar" target="_blank"><FacebookIcon style={{fontSize:'40' , color: '#01b3a7', margin:10}}/></a>
                                        <a href="https://linkedin.com/in/exoticmadagascar" target="_blank" ><LinkedInIcon style={{fontSize:'40', color: '#01b3a7', margin:10}}/></a>
                                        <a href="https://youtube.com/user/exoticmadagascar" target="_blank"><YouTubeIcon style={{fontSize:'40', color: '#01b3a7', margin:10}}/></a>
                                    </div>
                                </div>
                            </div>

                            <div className="text-center">
                                <div className="py-1">

                                    <p className="textombrenoir" style={{ color:'white',fontSize:"20px"}}>
                                      Copyright &copy; {new Date().getFullYear()} Tous les droits réservés à Exotic Madagascar.
                                    </p>
                                                                                      
                                </div>
                            </div>

                          </Zoom>

                        </div>

                        </div>

                    </BrowserView>

                    <MobileView>

                      <div id="contact" className="justify-content-center footer">

                        <div className="container">

                        <div className="container">
                          <div className="column">
                            <Fade left>
                            <div className="col">
                              <p style={{color:"white", fontWeight:"bold", fontSize:"1.5em", marginTop:'20px'}}>Contact</p>
                              {/* 
                              <div className="contactlist'">  
                                  <a href="tel:+000000000" target="_blank"><CallIcon style={{fontSize:'30' , color: '#01b3a7', marginTop:10,marginBottom:10, marginRight:10}}/></a>
                                  <a href="tel:+000000000" target="_blank"></a>
                              </div>
                              */}

                              <div className="contactlist'">  
                                  <a href="mailto:contact@exotic-madagascar.net" target="_blank"><MailIcon style={{fontSize:'30' , color: '#01b3a7', marginTop:10,marginBottom:10, marginRight:10}}/></a>
                                  <a href="mailto:contact@exotic-madagascar.net" target="_blank">contact@exotic-madagascar.net</a>
                              </div>

                              {/*
                              <div className="contactlist'">  
                                  <a href="mailto:armeltourguide@gmail.com" target="_blank"><MailIcon style={{fontSize:'30' , color: '#01b3a7', marginTop:10,marginBottom:10, marginRight:10}}/></a>
                                  <a href="mailto:armeltourguide@gmail.com" target="_blank">Mail to the guide</a>
                              </div>
                              */}

                              <div className="contactlist'">  
                                  <a href="https://facebook.com/ArmelMadagascar" target="_blank"><SendIcon style={{fontSize:'30' , color: '#01b3a7', marginTop:10,marginBottom:10, marginRight:10}}/></a>
                                  <a href="tel:+000000000" target="_blank">Antananarivo 101</a>
                              </div>
                              
                            </div>
                            </Fade>

                            <Fade right>
                            <div className="col">
                              <p style={{color:"white", fontWeight:"bold", fontSize:"1.5em", marginTop:'20px'}}>Support</p>
                              <div className="contactlist'">  
                                  <a href="https://facebook.com/ArmelMadagascar" target="_blank"><FacebookIcon style={{fontSize:'30' , color: '#01b3a7', marginTop:10,marginBottom:10, marginRight:10}}/></a>
                                  <a href="https://facebook.com/ArmelMadagascar" target="_blank">Exotic Madagascar</a>
                              </div>

                              <div className="contactlist'">  
                                  <a href="https://linkedin.com/in/exoticmadagascar" target="_blank"><LinkedInIcon style={{fontSize:'30' , color: '#01b3a7', marginTop:10,marginBottom:10, marginRight:10}}/></a>
                                  <a href="https://linkedin.com/in/exoticmadagascar" target="_blank">Exotic Madagascar</a>
                              </div>

                              <div className="contactlist'">  
                                  <a href="https://youtube.com/user/exoticmadagascar" target="_blank"><YouTubeIcon style={{fontSize:'30' , color: '#01b3a7', marginTop:10,marginBottom:10, marginRight:10}}/></a>
                                  <a href="https://youtube.com/user/exoticmadagascar" target="_blank">Exotic Madagascar</a>
                              </div>
                            </div>
                            </Fade>
                            
                          </div>

                        </div>

                          <Zoom>

                            <div className="row">
                                <div className="text-center">
                                    
                                    <div className="py-1">
                                        <a href="https://web.facebook.com/theharmelsmadagascar" target="_blank"><FacebookIcon style={{fontSize:'40' , color: '#01b3a7', margin:10}}/></a>
                                        <a href="https://linkedin.com/in/madaphototours" target="_blank" ><LinkedInIcon style={{fontSize:'40', color: '#01b3a7', margin:10}}/></a>
                                        <a href="https://youtube.com/user/madaphototours" target="_blank"><YouTubeIcon style={{fontSize:'40', color: '#01b3a7', margin:10}}/></a>
                                    </div>
                                </div>
                            </div>

                            <div className="text-center">
                                <div className="py-1">

                                    <p className="textombrenoir" style={{ color:'white',fontSize:"20px"}}>
                                      Copyright &copy; {new Date().getFullYear()} Tous les droits réservés à Exotic Madagascar.
                                    </p>
                                                                                      
                                </div>
                            </div>

                          </Zoom>

                        </div>

                        </div>

                    </MobileView>
                   

              </div>
        );
      }
  
}

export default Accueil;
