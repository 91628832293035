import './App.css';
import React,  {Alert,Component} from 'react';
import logo from './logo.png';
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade,EffectCreative } from 'swiper';
import { Pagination, Navigation,Autoplay } from "swiper";
import { useRef, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import 'swiper/css/effect-fade';
import "swiper/css/effect-creative";
import { Nav,Navbar,Form,Button,Container,Row,Col,NavDropdown,Card,Modal} from 'react-bootstrap';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import MailIcon from '@material-ui/icons/Mail';
import CallIcon from '@material-ui/icons/Call';
import HouseIcon from '@material-ui/icons/House';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { BrowserRouter as Router, Switch, Route, Link,Redirect,HashRouter } from 'react-router-dom';
import Bounce from 'react-reveal/Bounce';
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import Carousel from 'react-bootstrap/Carousel';
import { BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import Menu from './components/Menu';
import Accueil from './components/Accueil';
import Login from './components/Login';
import Connexion from './components/Connexion';
import Deconnexion from './components/Deconnexion';
import Acceuildata from './components/Acceuildata';
import Gallerydata from './components/Gallerydata';
import Toursdata from './components/Toursdata';
import Listopinion from './components/Listopinion';


class App extends Component{
    
  constructor(props) {
  super(props);
    this.state = {
      loaddata : true,
      show: false,
      setShow: false,
      isModalVisible: false,
      isModalloderVisible : true,
      accueiltitre: '',
      accueilimage: '',
      touteslesarticles: [],
      touteslestours: [],
      gallery: [],
      width: 0,
      height: 0,
      scrollpage:0,
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
      
  }
  
  async componentDidMount() {
    
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    window.addEventListener('scroll', this.updateWindowDimensions);
      
  };

  componentWillUnmount() {

    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });

    window.removeEventListener('resize', this.updateWindowDimensions);
    window.removeEventListener('scroll', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight, scrollpage: window.scrollY });
  }

  scrolltop(){
    window.scrollTo(0, 0)
  }
  
  render() {

        return (
            
              <div>

                <div style={{position:"fixed", width:"100%", top:0, zIndex: '1000'}}>

                  <Menu />

                </div>


                    
                    {this.state.scrollpage>200 ?

                        <Zoom>
                          <button className="bnttop" onClick={() => this.scrolltop()} style={{width:'50px', height:'50px', marginBottom:"70px",padding:"0%"}}><span><KeyboardDoubleArrowUpIcon /></span></button>
                        </Zoom>

                        :

                        null
                    
                    }
                    
                    <Router>

                      <Switch>
                            
                            <Route  path="/" exact component={Accueil}></Route>
                            <Route  path="/Accueil" exact component={Accueil} title="Accueil"></Route>
                            <Route  path="/Connexion" exact component={Connexion} title="Connexion"></Route>
                            <Route  path="/Deconnexion" exact component={Deconnexion} title="Deconnexion"></Route>
                            <Route  path="/Acceuildata" exact component={Acceuildata} title="Acceuildata"></Route>
                            <Route  path="/Toursdata" exact component={Toursdata} title="Toursdata"></Route>
                            <Route  path="/Gallerydata" exact component={Gallerydata} title="Gallerydata"></Route>
                            <Route  path="/Listopinion" exact component={Listopinion} title="Listopinion"></Route>
                            
                                                  
                      </Switch>

                  </Router>          

              </div>
        );
      }
  
}

export default App;
