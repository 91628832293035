
import React,  {Alert,Component} from 'react';
import {useEffect} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { green, purple } from '@material-ui/core/colors';
import Autocomplete from '@material-ui/lab/Autocomplete';
import queryString from 'query-string';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import '../App.css';
import { Nav,Navbar,Button,Form,Row,Modal,Col,table,thead,tr,th,tbody,td,Container} from 'react-bootstrap';
import Loader from "react-js-loader";
import { Rating } from 'react-simple-star-rating'


class Listopinion extends Component{
    
    constructor(props) {
    super(props);
      this.state = {
  
        selectedFile: null,
        selectedOption: null,
        listuser: [],
        modalupdate: false,
        modaldelete: false,
        listfile : [],
        allopinionuser:[],
        idlistfile:0,
        title:'',
        description:'',
        loadershow:false,
        imagefilename:'',
        tokenvalue : '',
        iduser:0,
        nom:'',
        prenoms:'',
        mail:'',
        ratingvalue:0,
        avisdescription:'',
      };

  }

  componentWillMount() {

    document.title = "Opinion list";

    let activeadmin = sessionStorage.getItem('usertype');
  
    if(activeadmin === null || activeadmin === 'Undefined'){

      this.setState({ tokenexist: false})
      return

    }else{

      this.setState({ tokenvalue: activeadmin})
      this.reloaddata();
      
    }

  }

  reloaddata () {

    if(this.state.tokenvalue !== ''){

          this.setState({ reload: true})
          
    }

  }
    
  async componentDidMount() { 

    const response = await fetch('https://exotic-madagascar.net/wbs/users/selectopinion.php');
    const data = await response.json();

    if(JSON.stringify(data).replace(/\"/g, "") !== '0'){
      this.setState({
        allopinionuser: data,
        idsuer:0,
        nom:'',
        prenoms:'',
        mail:'',
        ratingvalue:0,
        avisdescription:'',
      })
    }
  
  }
  

loaderdesactive(){

  this.setState({
    loadershow: false,
    selectedFile:'',
    extensionfile:'',
    imagefilename:'',
    textloading:''
  });
  
}

showdeletefile(idutilisateur,prenomutilisateur,nomutilisateur,mailutilisateur,avis,descriptionavis){
  
  this.setState({
    iduser:idutilisateur,
    nom:nomutilisateur,
    prenoms:prenomutilisateur,
    mail: mailutilisateur,
    ratingvalue:avis,
    avisdescription:descriptionavis,
    modaldelete: true
  })

}

deleteuser(){

  fetch(`https://exotic-madagascar.net/wbs/users/deleteuser.php`,{                  
        
    method:'post',
    headers: {'Content-Type':'application/x-www-form-urlencoded'},
    body: queryString.stringify({
      userid: this.state.iduser,
    })

    })

    .then(response => response.json())
    .then(data => {

    if(JSON.stringify(data).replace(/\"/g, "") === 'Failed'){

      this.setState({ errorform: true, errormessage: 'Error delete user.' });
      return;

    }

    this.setState({
      idsuer:0,
      nom:'',
      prenoms:'',
      mail:'',
      ratingvalue:0,
      avisdescription:'',
      modaldelete: false
    })

    this.refreshdata();

    })
    .catch(error => {
      this.setState({ tokenuser: error});
    });  

}

async refreshdata(){

  const response = await fetch('https://exotic-madagascar.net/wbs/users/selectopinion.php');
  const data = await response.json();

  if(JSON.stringify(data).replace(/\"/g, "") !== '0'){
    this.setState({
      allopinionuser: data,
      idsuer:0,
      nom:'',
      prenoms:'',
      mail:'',
      ratingvalue:0,
      avisdescription:'',
    })
  }else{
    this.setState({
      allopinionuser: [],
      idsuer:0,
      nom:'',
      prenoms:'',
      mail:'',
      ratingvalue:0,
      avisdescription:'',
    })
  }
  
}
    
  render() {

    const onPointerMove = (value,index) => this.handlePointerMove(value, index)

          return (
              
                <div className="App" style={{marginTop:'100px'}}>
                  
                  {this.state.tokenvalue === '' ?
                            

                            <Redirect push to="/"/>
                              

                            :

                            null

                    }

    
                  {this.state.modaldelete === true ?

                  <Modal

                  show={this.state.modaldelete}
                  onHide={ () => this.setState({modaldelete:false})}
                  dialogClassName="modal-dialog"
                  aria-labelledby="example-custom-modal-styling-title" centered
                  >
                    <Modal.Body>
                                
                    <div className="d-flex flex-row justify-content-center">
                                      
                        <div className="d-flex flex-column" style={{margin:"20px"}}>
                            
                            <div className="d-flex flex-column">
                                <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>Would you like to delete?</p>
                                <p>{this.state.prenoms} {this.state.nom}</p>
                                <p>{this.state.mail}</p>
                                <p>{this.state.avisdescription}</p>

                                <form>

                                <div className='row text-white d-flex align-items-center'>
                                            
                                      <Button className="btn"style={{ margin:"20px", width:'150px'}} onClick={ () => this.setState({modaldelete:false})} variant="primary">Close</Button>
                                      <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.deleteuser()} variant="danger">Delete</Button>

                                </div>
                                
                              </form>
                            </div>
                            
                        </div>

                    </div>    

                  </Modal.Body>
                  </Modal>

                  :

                  null

                  }

                  {this.state.allopinionuser.length>0?

                    <div className='centrervue' >
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            
                            <TableCell style={{fontWeight:'bold'}}>Full name</TableCell>
                            <TableCell style={{fontWeight:'bold'}}>Email</TableCell>
                            <TableCell style={{fontWeight:'bold'}}>Rating score</TableCell>
                            <TableCell style={{fontWeight:'bold'}}>Rating description</TableCell>
                            
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.allopinionuser.map((opinion) => (
                            <TableRow key={opinion.idutilisateur}>

                              <TableCell component="th" scope="row" className='verticalcenter'>{opinion.prenomutilisateur} {opinion.nomutilisateur}</TableCell>
                              <TableCell component="th" scope="row" className='verticalcenter'>{opinion.mailutilisateur}</TableCell>
                              <TableCell component="th" scope="row" className='verticalcenter'><Rating onPointerMove={onPointerMove}  initialValue={opinion.avis} readonly={true}/></TableCell>
                              <TableCell component="th" scope="row" className='verticalcenter'>{opinion.descriptionavis}</TableCell>
                              <TableCell ><button type="submit" className="btn btn-danger btn-circle btn-xl" onClick={() => this.showdeletefile(opinion.idutilisateur,opinion.prenomutilisateur,opinion.nomutilisateur,opinion.mailutilisateur,opinion.avis,opinion.descriptionavis)}><span><DeleteIcon /></span></button></TableCell>


                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    </div> 

                    :


                    <p>No user</p>

                  }
                  
               
                </div>
                
          );
        }
    
}

export default Listopinion;
