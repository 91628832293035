import '../App.css';
import React,  {Alert,Component} from 'react';
import {useEffect} from 'react';
import { BrowserRouter as Router, Switch, Route, Link,Redirect,HashRouter } from 'react-router-dom';
import { Nav,Navbar,Button,Form,Container,Row,Col,Dropdown,NavDropdown, Modal} from 'react-bootstrap';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { green, purple } from '@material-ui/core/colors';
import Autocomplete from '@material-ui/lab/Autocomplete';
import queryString from 'query-string';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { BrowserView, MobileView, isBrowser, isMobile, isWindows} from "react-device-detect";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Rating } from 'react-simple-star-rating'

class Menu extends Component{
    
    constructor(props) {
    super(props);
      this.state = {
        idtype:0,
        tokenuser:[],
        tokenexist: true,
        tokenvalue: '',
        tokendeconnect: '',
        fullname: '',
        admin:'',
        useractive:'',
        userinactive:'',
        active:'',
        show:false,
        nom:'',
        prenoms:'',
        mail:'',
        motdepasse:'',
        ismodalinscription: false,
        ismodallogin:false,
        ismodalupdate:false,
        ratingvalue:0,
        avisdescription:'',
      };
        
  }

  componentWillMount() {

    let userconnected = sessionStorage.getItem('userid');

    if(parseInt(userconnected)>0){
      this.setState({ useractive: 'yes'})
    }

    if(userconnected === null || userconnected === 'Undefined' || userconnected === 'null' || userconnected === false){
      this.setState({ userinactive: 'yes'})
    }
    
    let activeadmin = sessionStorage.getItem('usertype');
    
    if(activeadmin === null || activeadmin === 'Undefined' || activeadmin === 'null' || activeadmin === false){
      this.setState({ tokenexist: false, tokendeconnect : 'yes', admin:''})
      return
    }else{
      if(activeadmin === 'fullstack'){
        this.setState({ active: activeadmin, tokendeconnect : '', admin:'yes'})
      }  
    }

  }
    
   componentDidMount() { 


  }

  showDropdown = (e)=>{
    this.setState({ show: true, setShow:true})
    
  }

  hideDropdown = e => {
    this.setState({ show: false, setShow:false})
  }

  inscription(){

    if(this.state.nom.length == 0 && this.state.prenoms.length == 0) {

      alert("Please add your name or lastname.");
            
      return;

    }

    if (typeof(this.state.mail) == 'undefined') {

      alert("Please add your mail address.");
            
      return;

    }

    if (typeof(this.state.mail) !== undefined) {

        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        if (!pattern.test(this.state.mail)) {

            alert("Please write an exact mail address.");
            
            return;

        }
          
    }

    if(this.state.motdepasse.length < 8) {

      alert("Password must be minimum 8 characters.");
            
      return;

    }

    fetch(`https://exotic-madagascar.net/wbs/users/createuser.php`,{                  
            
                  method:'post',
                  headers: {'Content-Type':'application/x-www-form-urlencoded'},
                  body: queryString.stringify({

                    Nom: this.state.nom,
                    Prenoms: this.state.prenoms,
                    Email:this.state.mail,
                    Motdepasse:this.state.motdepasse,

                  })

              })

              .then(response => response.json())
              .then(data => {

                if(JSON.stringify(data).replace(/\"/g, "") === 'User exist'){
                  this.setState({ errorform: true, errormessage: 'User is exist.' });

                  return;

                }

                if(JSON.stringify(data).replace(/\"/g, "") === 'Error insert'){
                  this.setState({ errorform: true, errormessage: 'Error on server. Please repeat again.' });

                  return;

                }

                if(JSON.stringify(data).replace(/\"/g, "") === 'OK'){

                  alert("Your registration has been completed. You can log in to edit your profil.");

                  this.setState({
                    errorform: false,
                    errormessage:'',
                    nom:'',
                    prenoms:'',
                    mail:'',
                    motdepasse:'',
                    ismodalinscription:false,
                    //signin : true,
                  });     

                }            
                         
              })

              .catch(error => {
                    this.setState({ tokenuser: error});
              });  

  }

  login(){

    if (typeof(this.state.mail) == 'undefined') {

      alert("Please add your mail address.");
            
      return;

    }

    if (typeof(this.state.mail) !== undefined) {

        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        if (!pattern.test(this.state.mail)) {

            alert("Please write an exact mail address.");
            
            return;

        }
          
    }

    if(this.state.motdepasse.length < 8) {

      alert("Password error.");
            
      return;

    }

    fetch(`https://exotic-madagascar.net/wbs/users/connexion.php`,{                  
            
                  method:'post',
                  headers: {'Content-Type':'application/x-www-form-urlencoded'},
                  body: queryString.stringify({

                    mailutilisateur:this.state.mail,
                    motdepasseutilisateur:this.state.motdepasse,

                  })

              })

              .then(response => response.json())
              .then(data => {

                if(JSON.stringify(data).replace(/\"/g, "") === 'Usernotfound'){
                  alert("User not found. Please sign up or rectify your email address.");
                  //this.setState({ errorform: true, errormessage: 'User not found. Please sign up.' });

                  return;

                }

                if(JSON.stringify(data).replace(/\"/g, "") === 'Errorpassword'){
                  alert("Error password.");
                  //this.setState({ errorform: true, errormessage: 'Error password.' });

                  return;

                }

                if(parseInt(JSON.stringify(data[0].active).replace(/\"/g, "")) === 0){
                  alert("User not active.");
                  //this.setState({ errorform: true, errormessage: 'Error password.' });

                  return;

                }

                //if(JSON.stringify(data).replace(/\"/g, "") === 'Connected'){

                  this.setState({
                    errorform: false,
                    errormessage:'',
                    nom:'',
                    prenoms:'',
                    mail:'',
                    motdepasse:'',
                    ismodallogin:false,
                    
                    //signin : true,
                  }); 

                  sessionStorage.setItem('userid', JSON.stringify(data[0].idutilisateur).replace(/\"/g, ""));

                  if(JSON.stringify(data[0].typeutilisateur).replace(/\"/g, "") === 'fullstack'){
                    sessionStorage.setItem('usertype', JSON.stringify(data[0].typeutilisateur).replace(/\"/g, ""));
                  }
                  
                  window.location.reload();

                //}            
                         
              })

              .catch(error => {
                    this.setState({ tokenuser: error});
              });  

  }

  logout(){

    sessionStorage.clear();

    window.location.reload();

  }

  detailuser(){

    let userconnected = sessionStorage.getItem('userid');

    fetch(`https://exotic-madagascar.net/wbs/users/selectuserid.php`,{                  
            
                  method:'post',
                  headers: {'Content-Type':'application/x-www-form-urlencoded'},
                  body: queryString.stringify({
                    userid : userconnected,

                  })

              })

              .then(response => response.json())
              .then(data => {

                if(JSON.stringify(data).replace(/\"/g, "") === 'Usernotfound'){

                  alert('User not found. Please sign up or log in.')

                  return;

                }

                  this.setState({

                    nom:JSON.stringify(data[0].nomutilisateur).replace(/\"/g, ""),
                    prenoms:JSON.stringify(data[0].prenomutilisateur).replace(/\"/g, ""),
                    ratingvalue : parseInt(JSON.stringify(data[0].avis).replace(/\"/g, "")),
                    avisdescription:JSON.stringify(data[0].descriptionavis).replace(/\"/g, ""),
                    ismodalupdate:true,

                  });     
          
                         
              })

              .catch(error => {
                    this.setState({ tokenuser: error});
              });  

  }

  update(){

    if(this.state.nom.length == 0 && this.state.prenoms.length == 0) {

      alert("Please add your name or lastname.");
            
      return;

    }

    let userconnected = sessionStorage.getItem('userid');

    fetch(`https://exotic-madagascar.net/wbs/users/updateuser.php`,{                  
            
                  method:'post',
                  headers: {'Content-Type':'application/x-www-form-urlencoded'},
                  body: queryString.stringify({
                    userid : userconnected,
                    Nom: this.state.nom,
                    Prenoms: this.state.prenoms,
                    avis : this.state.ratingvalue,
                    descriptionavis : this.state.avisdescription,

                  })

              })

              .then(response => response.json())
              .then(data => {

                if(JSON.stringify(data).replace(/\"/g, "") === 'Failed'){
                  alert('Update failed')

                  return;

                }

                if(JSON.stringify(data).replace(/\"/g, "") === 'OK'){

                  this.setState({
                    errorform: false,
                    errormessage:'',
                    nom:'',
                    prenoms:'',
                    mail:'',
                    motdepasse:'',
                    ratingvalue : 0,
                    avisdescription:'',
                    ismodalupdate:false,
                    //signin : true,
                  });     

                  window.location.reload();

                }            
                         
              })

              .catch(error => {
                    this.setState({ tokenuser: error});
              });  

  }

  handlePointerMove = (value, index) => {
    this.setState({ ratingvalue: value}) 
  }



  render() {

    const onPointerMove = (value,index) => this.handlePointerMove(value, index)

          return (

            <div style={{position:"fixed", width:"100%", marginTop:"0px", zIndex: '1000'}}>

                <Navbar collapseOnSelect expand="lg" className="navbar-edit navbar-fixed-top menu w-100" >
                  
                      
                      <p style={{marginTop:"12px", marginLeft:"20px", fontSize:"20px", color:"white"}}>Exotic Madagascar</p>
                      <Navbar.Toggle aria-controls="responsive-navbar-nav" className="togglecolor"/>
                      <Navbar.Collapse id="responsive-navbar-nav" className="rounded float-right" style={{marginLeft:"30px"}}>
                        <Nav
                          className="justify-content-end" style={{ width: "100%", marginRight:"30px"}}
                          
                        >
                          <Nav.Link href="#accueil" activeClass="active" className="nav-link" style={{color:"white"}}  spy={true} smooth={true} offset={-0} duration={500}>Accueil</Nav.Link>
                          <Nav.Link href="#tours" activeClass="active" className="nav-link" style={{color:"white"}}  spy={true} smooth={true} offset={0} duration={500}>Tours</Nav.Link>
                          <Nav.Link href="#gallery" activeClass="active" className="nav-link" style={{color:"white"}}  spy={true} smooth={true} offset={-0} duration={500}>Gallery</Nav.Link>
                          <Nav.Link href="#contact" activeClass="active" className="nav-link" style={{color:"white"}}  spy={true} smooth={true} offset={-0} duration={500}>Contact</Nav.Link>
                          
                          {this.state.admin && (

                            <NavDropdown title="Dashboard" id="collasible-nav-dropdown" show={this.state.show}
                            onMouseEnter={this.showDropdown} 
                            onMouseLeave={this.hideDropdown}
                            renderMenuOnMount={true}>

                              <NavDropdown.Item className="menudropdownhover" href="/Acceuildata" to="/Acceuildata">Accueil</NavDropdown.Item>
                              <NavDropdown.Item className="menudropdownhover" href="/Toursdata" to="/Toursdata">Tours</NavDropdown.Item>
                              <NavDropdown.Item className="menudropdownhover" href="/Gallerydata" to="/Gallerydata">Gallery</NavDropdown.Item>
                              <NavDropdown.Item className="menudropdownhover" href="/Listopinion" to="/Listopinion">List opinion</NavDropdown.Item>

                            </NavDropdown>    

                            )
                          }

                          {this.state.userinactive && (

                            <Nav.Link onClick={() => this.setState({ismodalinscription:true})} activeClass="active" spy={true} smooth={true} offset={-0} duration={500}>Sign up</Nav.Link>

                            )
                          }

                          {this.state.userinactive && (

                            <Nav.Link onClick={() => this.setState({ismodallogin:true})} activeClass="active" spy={true} smooth={true} offset={-0} duration={500}>Log in</Nav.Link>

                            )
                          }

                          {this.state.useractive && (

                            <Nav.Link onClick={() => this.logout()} activeClass="active" spy={true} smooth={true} offset={-0} duration={500}>Log out</Nav.Link>
                            
                            )
                          }

                          {this.state.useractive && (

                            <Nav.Link onClick={() => this.detailuser()} activeClass="active" className="nav-link" spy={true} smooth={true} offset={-100} duration={500} style={{color:"#f65ff3", paddingTop:'5px'}}><span ><AccountCircleIcon fontSize="large"/></span></Nav.Link>
                            
                            )
                          }

                          

                        </Nav>
                       
                      </Navbar.Collapse>
                   
                  </Navbar>
                      
                  {this.state.ismodalinscription == true?

                    <Modal
                    
                      show={this.state.ismodalinscription}
                      onHide={ () => this.setState({ismodalinscription:false})}
                      dialogClassName="modal-dialog"
                      aria-labelledby="example-custom-modal-styling-title" centered
                      >

                    <Modal.Body>
                                
                                              
                    <div style={{marginRight:"10px"}}>

                      <BrowserView>

                      <Container>
                        <Row>
                          <Col>

                            <div className="form-group" style={{margin:'10px'}}>
                              <label>Last name</label>
                              <input type="text" className="form-control" value={this.state.nom} onChange={(e) => this.setState({ nom: e.target.value})} />
                            </div>

                            <div className="form-group" style={{margin:'10px'}}>
                              <label>First name</label>
                              <input type="text" className="form-control" value={this.state.prenoms} onChange={(e) => this.setState({ prenoms: e.target.value})} />
                            </div>

                            <div className="form-group" style={{margin:'10px'}}>
                              <label>Email *</label>
                              <input type="email" className="form-control" aria-describedby="emailHelp" value={this.state.mail} onChange={(e) => this.setState({ mail: e.target.value})} />
                            </div>   

                            <div className="form-group" style={{margin:'10px'}}>
                              <label>Password *</label>
                              <input type="password" className="form-control" value={this.state.motdepasse} onChange={(e) => this.setState({ motdepasse: e.target.value})} />
                            </div>
                          
                          </Col>

                        
                        </Row>
                      </Container>

                      </BrowserView>

                      <MobileView>
                                
                        <div className="form-group" style={{margin:'10px'}}>
                            <label>Last name</label>
                            <input type="text" className="form-control" value={this.state.nom} onChange={(e) => this.setState({ nom: e.target.value})} />
                          </div>

                          <div className="form-group" style={{margin:'10px'}}>
                            <label>First name</label>
                            <input type="text" className="form-control" value={this.state.prenoms} onChange={(e) => this.setState({ prenoms: e.target.value})} />
                          </div>

                          <div className="form-group" style={{margin:'10px'}}>
                            <label>Email *</label>
                            <input type="email" className="form-control" aria-describedby="emailHelp" value={this.state.mail} onChange={(e) => this.setState({ mail: e.target.value})} />
                          </div>   

                          <div className="form-group" style={{margin:'10px'}}>
                            <label>Password *</label>
                            <input type="password" className="form-control" value={this.state.motdepasse} onChange={(e) => this.setState({ motdepasse: e.target.value})} />
                          </div>

                      </MobileView>
                                                                  
                      <div className="d-flex justify-content-center">
                          <Button className="btn btn-danger"style={{ margin:"10px", width:'150px', marginRight:'50px'}} onClick={ () => this.setState({ismodalinscription:false})} variant="primary">Close</Button>
                          <Button className="btn bntsign" style={{ margin:"10px",right: 0, width:'150px',backgroundColor: "#01b3a7",border: 0,}} onClick={() => this.inscription()} >Sign up</Button>   
                      </div>

                      </div>
                      
                    </Modal.Body>
                    </Modal>

                  
                    :

                    null

                  }

                  {this.state.ismodallogin == true?

                  <Modal

                    show={this.state.ismodallogin}
                    onHide={ () => this.setState({ismodallogin:false})}
                    dialogClassName="modal-dialog"
                    aria-labelledby="example-custom-modal-styling-title" centered
                    >

                  <Modal.Body>
                              
                                            
                  <div style={{marginRight:"10px"}}>

                    <BrowserView>

                    <Container>
                      <Row>
                        <Col>

                          <div className="form-group" style={{margin:'10px'}}>
                            <label>Email *</label>
                            <input type="email" className="form-control" aria-describedby="emailHelp" value={this.state.mail} onChange={(e) => this.setState({ mail: e.target.value})} />
                          </div>   

                          <div className="form-group" style={{margin:'10px'}}>
                            <label>Password *</label>
                            <input type="password" className="form-control" value={this.state.motdepasse} onChange={(e) => this.setState({ motdepasse: e.target.value})} />
                          </div>
   
                        </Col>

                      
                      </Row>
                    </Container>

                    </BrowserView>

                    <MobileView>
                              
                        <div className="form-group" style={{margin:'10px'}}>
                          <label>Email *</label>
                          <input type="email" className="form-control" aria-describedby="emailHelp" value={this.state.mail} onChange={(e) => this.setState({ mail: e.target.value})} />
                        </div>   

                        <div className="form-group" style={{margin:'10px'}}>
                          <label>Password *</label>
                          <input type="password" className="form-control" value={this.state.motdepasse} onChange={(e) => this.setState({ motdepasse: e.target.value})} />
                        </div>

                    </MobileView>
                                                                
                    <div className="d-flex justify-content-center">
                        <Button className="btn btn-danger"style={{ margin:"10px", width:'150px', marginRight:'50px'}} onClick={ () => this.setState({ismodallogin:false})} variant="primary">Close</Button>
                        <Button className="btn bntsign" style={{ margin:"10px",right: 0, width:'150px',backgroundColor: "#01b3a7",border: 0,}} onClick={() => this.login()} >Login</Button>   
                    </div>

                    </div>
                    
                  </Modal.Body>
                  </Modal>


                  :

                  null

                  }

                  {this.state.ismodalupdate == true?

                  <Modal

                    show={this.state.ismodalupdate}
                    onHide={ () => this.setState({ismodalupdate:false})}
                    dialogClassName="modal-dialog"
                    aria-labelledby="example-custom-modal-styling-title" centered
                    >

                  <Modal.Body>
                              
                                            
                  <div style={{marginRight:"10px"}}>

                    <BrowserView>

                    <Container>
                      <Row>
                        <Col>

                          <div className="form-group" style={{margin:'10px'}}>
                            <label>Last name</label>
                            <input type="text" className="form-control" value={this.state.nom} onChange={(e) => this.setState({ nom: e.target.value})} />
                          </div>

                          <div className="form-group" style={{margin:'10px'}}>
                            <label>First name</label>
                            <input type="text" className="form-control" value={this.state.prenoms} onChange={(e) => this.setState({ prenoms: e.target.value})} />
                          </div>

                          <div className="form-group" style={{margin:'10px'}}>
                            <Rating
                              onPointerMove={onPointerMove}
                              initialValue={this.state.ratingvalue}
                            />
                          </div>

                          <div className="form-group" style={{margin:'10px'}}>
                            <label>Your opinion</label>
                            <Form.Control  size="sl" as="textarea" rows={4} placeholder="Your opinion about exotic Madagascar ..." ref="avisdescription" value={this.state.avisdescription} onChange={(e) => this.setState({ avisdescription: e.target.value})} />
                          </div>

                        </Col>

                      
                      </Row>
                    </Container>

                    </BrowserView>

                    <MobileView>
                              
                      <div className="form-group" style={{margin:'10px'}}>
                          <label>Last name</label>
                          <input type="text" className="form-control" value={this.state.nom} onChange={(e) => this.setState({ nom: e.target.value})} />
                        </div>

                        <div className="form-group" style={{margin:'10px'}}>
                          <label>First name</label>
                          <input type="text" className="form-control" value={this.state.prenoms} onChange={(e) => this.setState({ prenoms: e.target.value})} />
                        </div>

                        <div className="form-group" style={{margin:'10px'}}>
                          <Rating
                            onPointerMove={onPointerMove}
                            initialValue={this.state.ratingvalue}
                          />
                        </div>

                        <div className="form-group" style={{margin:'10px'}}>
                          <label>Your opinion</label>
                          <Form.Control  size="sl" as="textarea" rows={3} placeholder="Your opinion about exotic Madagascar ..." ref="avisdescription" value={this.state.avisdescription} onChange={(e) => this.setState({ avisdescription: e.target.value})} />
                        </div>

                    </MobileView>
                                                                
                    <div className="d-flex justify-content-center">
                        <Button className="btn btn-danger"style={{ margin:"10px", width:'150px', marginRight:'50px'}} onClick={ () => this.setState({ismodalupdate:false})} variant="primary">Close</Button>
                        <Button className="btn bntsign" style={{ margin:"10px",right: 0, width:'150px',backgroundColor: "#01b3a7",border: 0,}} onClick={() => this.update()} >Update</Button>   
                    </div>

                    </div>
                    
                  </Modal.Body>
                  </Modal>


                  :

                  null

                  }

                  {this.state.signin == true ?
                            

                    <Redirect push to="/"/>
                      

                    :

                    null

                  }
                  
                </div>

               
          );
        }
    
}

export default Menu;
