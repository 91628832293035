import React,  {Alert,Component} from 'react';
import { Redirect  } from 'react-router-dom';

class Connexion extends Component{
    
    constructor(props) {
    super(props);

    this.state = {
  
      refreshfinish: '',

    };
        
  }


  componentWillMount() {

    


  }
    
    componentDidMount() {

      document.title = "Loading data ...";

      let activeadmin = sessionStorage.getItem('active');

      if(activeadmin === '' || activeadmin === 'undefined'){
        this.setState({ tokenexist: false})
      }else{

        this.refreshdata();

      }

    };

    refreshdata() {

      var refreshfinish = sessionStorage.getItem('refreshdata');

      if(refreshfinish === 'no'){
        sessionStorage.setItem('refreshdata', 'yes');
       
        this.setState({ refreshfinish: 'yes'})
        window.location.reload(false);

      }

    }
    
  render() {

          return (
              
                <div className="d-flex justify-content-center" style={{marginTop:'250px'}}>

                    
                   {this.state.refreshfinish === 'yes' ?
                            

                        <Redirect push to="/Accueil"/>
                          

                        :

                        <Redirect push to="/Accueil"/>

                  }

                </div>
          );
        }
    
}

export default Connexion;
