
import React,  {Alert,Component} from 'react';
import {useEffect} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { green, purple } from '@material-ui/core/colors';
import Autocomplete from '@material-ui/lab/Autocomplete';
import queryString from 'query-string';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import '../App.css';
import { Nav,Navbar,Button,Form,Row,Modal,Col} from 'react-bootstrap';
import Loader from "react-js-loader";
import { convertToRaw, convertFromRaw, EditorState, Editor,ContentState,RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import { stateToHTML } from "draft-js-export-html";
import draftToHtmlPuri from "draftjs-to-html";


class Toursdata extends Component{
    
    constructor(props) {
    super(props);
      this.state = {
        editorState: EditorState.createEmpty(),
        selectedFile: null,
        selectedOption: null,
        listuser: [],
        modaladd:false,
        modalupdate: false,
        modaldelete: false,
        modaladdimage : false,
        listfile : [],
        listimage : [],
        idimagetours :0,
        titletoursedit:'',
        idlistfile:0,
        title:'',
        description:'',
        roadmap:'',
        loadershow:false,
        imagefilename:'',
        editorContentHtml:'',
        tokenvalue : '',
       
      };

  }

  componentWillMount() {

    document.title = "Tours Data";

    let tokenuser = sessionStorage.getItem('usertype');
  
    if(tokenuser === null || tokenuser === 'Undefined'){

      this.setState({ tokenexist: false})
      return

    }else{

      this.setState({ tokenvalue: tokenuser})
      this.reloaddata();
      
    }

  }

  reloaddata () {

    if(this.state.tokenvalue !== ''){

          this.setState({ reload: true})
          
    }

  }
    
  async componentDidMount() { 


    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });

    const response = await fetch('https://exotic-madagascar.net/wbs/tours/alltours.php');
    const data = await response.json();
    this.setState({ listfile: data})
  
  }

  onFileChange = event => { 
    // Update the state 
    this.setState({
      selectedFile: event.target.files[0],
      imagefilename : event.target.files[0].name,
     }); 

  }; 
   
  // On file upload (click the upload button) 
  addtours = (htmlPuri) => { 

    if(this.state.selectedFile === null || this.state.selectedFile === 'null'){

      this.setState({
        loadershow: false,
        textloading:'Please select a file.'
      });
      
      return;
    
    }


    if(this.state.title === '' ){

      this.setState({
        loadershow: false,
        textloading:'Please put make a title.'
      });
      
      return;
    
    }

    if(this.state.description === '' ){

      this.setState({
        loadershow: false,
        textloading:'Please put make a description.'
      });
      
      return;
    
    }

    this.setState({
      loadershow: true,
      textloading:'Uploading file ...'
    });

    // Create an object of formData 
    const formData = new FormData(); 
   
    // Update the formData object 
    formData.append( 
      "image", 
      this.state.selectedFile, 
      this.state.selectedFile.name,
    ); 

    formData.append("title",this.state.title);
    formData.append("description",this.state.description);
    formData.append("roadmap",htmlPuri);
   
    // Details of the uploaded file 
   // console.log(this.state.selectedFile); 

   
    // Request made to the backend api 
    // Send formData object 
    axios.post("https://exotic-madagascar.net/wbs/tours/addfile.php", formData)
    .then(res => {
  
      //alert(JSON.stringify(res.data));
      this.setState({ listfile : res.data});

      this.loaderdesactive();

  })

}

loaderdesactive(){

  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });

  this.setState({
    loadershow: false,
    selectedFile:null,
    idlistfile: '',
    title: '',
    description: '',
    extensionfile:'',
    imagefilename:'',
    textloading:'',
    modaladd:false
  });
  
}

showaddtours(){

  this.setState({
    idlistfile: 0,
    title: '',
    description: '',
    roadmap:'',
    modaladd: true
  })

}

showupdatetours(idfile,title,description,roadmap,extensionfile){

  if(roadmap === null){

  }else{
    const { editorState } = this.state;
    this.onChange(EditorState.push(editorState, convertFromHTML(roadmap)));
  }

  this.setState({
    idlistfile: idfile,
    title: title,
    description: description,
    roadmap:roadmap,
    extensionfile : extensionfile,
    modalupdate: true
  })

}

updatetours(htmlPuri){

  this.setState({
    loadershow: true,
    textloading: 'Updating data and file ...'
  });

  if(this.state.selectedFile === null){

    fetch(`https://exotic-madagascar.net/wbs/tours/updatefile.php`,{                  
        
      method:'post',
      headers: {'Content-Type':'application/x-www-form-urlencoded'},
      body: queryString.stringify({
        idtours: this.state.idlistfile,
        title : this.state.title,
        description:this.state.description,
        roadmap:htmlPuri,
        extensionfile:this.state.extensionfile,
      })

      })

      .then(response => response.json())
      .then(data => {

      if(JSON.stringify(data).replace(/\"/g, "") === 'Error'){

        this.setState({ errorform: true, errormessage: 'Error update file.' });
        return;
      
      }

        this.setState({
          loadershow: false,
          selectedFile:null,
          idlistfile: '',
          title: '',
          description: '',
          extensionfile:'',
          imagefilename:'',
          textloading:'',
          modalupdate: false
        })

        this.refreshdata();

      })
      .catch(error => {
        this.setState({ errormessage: error});
      });  

  }else{

    const formData = new FormData(); 
   
    formData.append( 
      "image", 
      this.state.selectedFile, 
      this.state.selectedFile.name,
    ); 

    formData.append("idfile",this.state.idlistfile);
    formData.append("extension",this.state.extensionfile);
   
    axios.post("https://exotic-madagascar.net/wbs/tours/removeaddfile.php", formData)
    .then(res => {

      fetch(`https://exotic-madagascar.net/wbs/tours/updatefile.php`,{                  
        
        method:'post',
        headers: {'Content-Type':'application/x-www-form-urlencoded'},
        body: queryString.stringify({
          idtours: this.state.idlistfile,
          title : this.state.title,
          description:this.state.description,
          roadmap:htmlPuri,
          extensionfile:this.state.extensionfile,
        })
  
        })
  
        .then(response => response.json())
        .then(data => {
  
        if(JSON.stringify(data).replace(/\"/g, "") === 'Error'){
  
          this.setState({ errorform: true, errormessage: 'Error update file.' });
          return;
        
        }
  
          this.setState({
            loadershow: false,
            selectedFile:null,
            idlistfile: '',
            title: '',
            description: '',
            extensionfile:'',
            imagefilename:'',
            textloading:'',
            modalupdate: false
          })
  
          this.refreshdata();
  
        })
        .catch(error => {
          this.setState({ errormessage: error});
        });  

  })


  }

}

showdeletetours(idtours,titletours,extensionimage){
  
  this.setState({
    idlistfile: idtours,
    title: titletours,
    extensionfile:extensionimage,
    modaldelete: true
  })

}

async selectimagetours(idtours,tourstitle){

  this.setState({
    loadershow: false,
    selectedFile:null,
    idlistfile: idtours,
    titletoursedit: tourstitle,
    modaladdimage: true
  })

  const response = await fetch('https://exotic-madagascar.net/wbs/tours/gallerytours/selectimagetours.php',{                  
        method:'post',
        headers: {'Content-Type':'application/x-www-form-urlencoded'},
        body: queryString.stringify({
          idtrs:idtours
        })});
  const data = await response.json();

  this.setState({
    listimage:data,
  })

}

addimagetours = () => { 

  if(this.state.selectedFile === null || this.state.selectedFile === 'null'){

    this.setState({
      loadershow: false,
      textloading:'Please select a file.'
    });
    
    return;
  
  }

  this.setState({
    loadershow: true,
    textloading:'Uploading file ...'
  });

  // Create an object of formData 
  const formData = new FormData(); 
 
  // Update the formData object 
  formData.append( 
    "image", 
    this.state.selectedFile, 
    this.state.selectedFile.name,
  ); 

  formData.append("idtours",this.state.idlistfile);
 
  // Request made to the backend api 
  // Send formData object 
  axios.post("https://exotic-madagascar.net/wbs/tours/gallerytours/addfile.php", formData)
  .then(res => {

    this.setState({
      listimage : res.data,
      loadershow: false,
      textloading:'',
    });

  })

}

deleteimagetours(){

  fetch(`https://exotic-madagascar.net/wbs/tours/gallerytours/deletefile.php`,{                  
        
    method:'post',
    headers: {'Content-Type':'application/x-www-form-urlencoded'},
    body: queryString.stringify({
      idfile: this.state.idimagetours,
      extension : this.state.extensionfile,
      idtrs: this.state.idlistfile
    })

    })

    .then(response => response.json())
    .then(data => {

    if(JSON.stringify(data).replace(/\"/g, "") === 'Error'){

      this.setState({ errorform: true, errormessage: 'Error delete file.' });
      return;

    }

    if(data.length>0){
      this.setState({
        selectedFile:null,
        listimage : data,
        modaldeleteimage: false,
      })
    }else{
      this.setState({
        selectedFile:null,
        listimage : [],
        modaldeleteimage: false,
      })
    }


    })
    .catch(error => {
      this.setState({
        tokenuser: error,
        listimage : [],
        modaldeleteimage: false,
      });
    });  

}

deletetours(){

  fetch(`https://exotic-madagascar.net/wbs/tours/deletetours.php`,{                  
        
    method:'post',
    headers: {'Content-Type':'application/x-www-form-urlencoded'},
    body: queryString.stringify({
      idfile: this.state.idlistfile,
      extension : this.state.extensionfile,
    })

    })

    .then(response => response.json())
    .then(data => {

    if(JSON.stringify(data).replace(/\"/g, "") === 'Error'){

      this.setState({ errorform: true, errormessage: 'Error delete file.' });
      return;

    }

    this.setState({
      idlistfile: 0,
      title: '',
      extensionfile:'',
      modaldelete: false,
    })

    this.refreshdata();

    })
    .catch(error => {
      this.setState({ tokenuser: error});
    });  

}

showdeleteimagetours(idtours,extensionfile){
  this.setState({
    loadershow: false,
    selectedFile:null,
    idimagetours: idtours,
    extensionfile : extensionfile,
    modaldeleteimage: true
  })
}

async refreshdata(){

  const response = await fetch('https://exotic-madagascar.net/wbs/tours/alltours.php');
  const data = await response.json();
  this.setState({
    listfile: data,
    loadershow: false,
    selectedFile:null,
    idlistfile: '',
    title: '',
    description: '',
    extensionfile:'',
    imagefilename:'',
    textloading:'',
  })

}

onEditorStateChange = (editorState) => {
    
  this.setState({
    editorState
  });

  this.setState({
    texthtml:convertToHTML(editorState.getCurrentContent()),
  });

};

onChange = (editorState) => {
  this.setState({
    editorState
  });
  
}

onUnderlineClick = () => {
  this.onChange(
    RichUtils.toggleInlineStyle(this.state.editorState, "UNDERLINE")
  );
};

onBoldClick = () => {
  this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, "BOLD"));
};

onItalicClick = () => {
  this.onChange(
    RichUtils.toggleInlineStyle(this.state.editorState, "ITALIC")
  );
};
    
  render() {

    const htmlPuri = draftToHtmlPuri(
      convertToRaw(this.state.editorState.getCurrentContent())
    );

    const html = stateToHTML(this.state.editorState.getCurrentContent(), {
      inlineStyleFn: styles => {
        let key = "color-";
        let color = styles.filter(value => value.startsWith(key)).first();
        let a = "fontfamily-";
        let b = styles.filter(value => value.startsWith(a)).first();
        console.log(b, color);

        if (color) {
          return {
            element: "span",
            style: {
              color: color.replace(key, ""),
              fontFamily: b.replace(a, "")
            }
          };
        }
      }
    });


          return (
              
                <div className="App" style={{marginTop:'100px'}}>
                  
                  {this.state.tokenvalue === '' ?
                            

                            <Redirect push to="/"/>
                              

                            :

                            null

                    }

                    <Button className="btn"style={{ margin:"20px", width:'150px'}} onClick={ () => this.setState({modaladd:true})} variant="primary">ADD TOURS</Button>
                                  
                      {this.state.modaladd === true ?

                        <Modal

                        show={this.state.modaladd}
                        onHide={ () => this.setState({modaladd:false})}
                        dialogClassName="modal-dialog"
                        aria-labelledby="example-custom-modal-styling-title" centered
                        >
                          <Modal.Body>
                              <div className="d-flex flex-column justify-content-center">

                                <label>Title :</label>
                                <input style={{width:'100%', marginTop:'10px', marginBottom:'10px'}}type="text" className="form-control" value={this.state.title} onChange={(e) => this.setState({ title: e.target.value})} />
                              
                                <label>Description :</label>
                                <textarea type="textarea" rows={3} style={{width:'100%'}} className="form-control" value={this.state.description} onChange={(e) => this.setState({ description: e.target.value})} />

                                <div style={{marginTop:'25px',marginLeft:'0px'}}>
                                  <label>Road map :</label>
                                </div>
                                <div className='d-flex flex-row centerdata'>
                                  <button style={{width:'30px', height:'30px', borderRadius:'15px', margin:'15px', textDecoration:'underline'}} onClick={this.onUnderlineClick}>U</button>
                                  <button style={{width:'30px', height:'30px', borderRadius:'15px', margin:'15px', fontWeight:'bold'}} onClick={this.onBoldClick}>B</button>
                                  <button style={{width:'30px', height:'30px', borderRadius:'15px', margin:'15px', fontStyle: 'italic'}} onClick={this.onItalicClick}>I</button>
                                </div>
                                
                                <div style={{width:'100%', height:'150px', margin:'0px', textAlign:'left'}}>
                                          <Editor
                                            ClassName="editorstyle"
                                            editorState={this.state.editorState}
                                            onChange={this.onChange}
                                          />                              
                                        
                                        </div>
                                        <div  style={{maxWidth:"50vh"}} dangerouslySetInnerHTML={{ __html: htmlPuri}}></div>

                                {this.state.imagefilename === null ?

                                  <div >
                                      No select file                    
                                  </div>

                                  :
                                  <div style={{textAlign:'center'}}>
                                    {this.state.imagefilename}
                                  </div>
                                }

                                <label htmlFor="filePicker" style={{background:"#ffc107", padding:"5px 10px", marginTop:'20px', color:'white', borderRadius:'5px',textAlign:'center' }}>Select file</label>
                                <input id="filePicker" style={{visibility:"hidden"}} type={"file"} onChange={this.onFileChange} accept=".jpg"/>

                                {this.state.loadershow === true ?
                                  

                                  <Loader type="spinner-cub" bgColor={'#01b3a7'} title={this.state.textloading} color={'#01b3a7'} size={100} />
                                    
            
                                  :
            
                                  null
            
                                }

                                {this.state.loadershow === true ?
                              

                                  <Loader type="spinner-cub" bgColor={'#01b3a7'} title={this.state.textloading} color={'#01b3a7'} size={100} />
                                    
            
                                  :
            
                                  null
            
                                }
                                
                                <div className='d-flex flex-row justify-content-center'>
                                            
                                    <Button className="btn"style={{ margin:"10px", width:'150px'}} onClick={ () => this.setState({modaladd:false})} variant="primary">Close</Button>
                                    {this.state.selectedFile === null ?

                                      null

                                      :

                                      <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.addtours(htmlPuri)} variant="success">Add tours</Button>

                                    }
                                    
                                </div>

                            </div>          
                          
                        </Modal.Body>
                        </Modal>

                        :

                        null

                        }

                  
                  {this.state.modalupdate === true ?

                  <Modal
                  
                  show={this.state.modalupdate}
                  onHide={ () => this.setState({modalupdate:false})}
                  dialogClassName="modal-dialog"
                  aria-labelledby="example-custom-modal-styling-title" centered
                  >

                      <Modal.Body>
                          <div className="d-flex flex-column justify-content-center">

                            <label>Title :</label>
                            <input style={{width:'100%', marginTop:'10px', marginBottom:'10px'}}type="text" className="form-control" value={this.state.title} onChange={(e) => this.setState({ title: e.target.value})} />
                          
                            <label>Description :</label>
                            <textarea type="textarea" rows={3} style={{width:'100%'}} className="form-control" value={this.state.description} onChange={(e) => this.setState({ description: e.target.value})} />

                            <div style={{marginTop:'25px',marginLeft:'0px'}}>
                              <label>Road map :</label>
                            </div>
                            <div className='d-flex flex-row centerdata'>
                              <button style={{width:'30px', height:'30px', borderRadius:'15px', margin:'15px', textDecoration:'underline'}} onClick={this.onUnderlineClick}>U</button>
                              <button style={{width:'30px', height:'30px', borderRadius:'15px', margin:'15px', fontWeight:'bold'}} onClick={this.onBoldClick}>B</button>
                              <button style={{width:'30px', height:'30px', borderRadius:'15px', margin:'15px', fontStyle: 'italic'}} onClick={this.onItalicClick}>I</button>
                            </div>
                            
                            <div style={{width:'100%', height:'150px', margin:'0px', textAlign:'left'}}>
                                <Editor
                                  ClassName="editorstyle"
                                  editorState={this.state.editorState}
                                  onChange={this.onChange}
                                />                              
                              
                            </div>

                            <div  style={{maxWidth:"50vh"}} dangerouslySetInnerHTML={{ __html: htmlPuri}}></div>

                            <div className='d-flex justify-content-center'>
                              <img src={process.env.PUBLIC_URL + './images/tours/' + this.state.idlistfile + '.' + this.state.extensionfile} style={{width:"150px", height:"150px"}} className="d-flex justify-content-center"/>
                            </div>

                            {this.state.imagefilename === null ?

                              <div >
                                  No select file                    
                              </div>

                              :
                              <div style={{textAlign:'center'}}>
                                {this.state.imagefilename}
                              </div>
                            }

                            <label htmlFor="filePicker" style={{background:"#ffc107", padding:"5px 10px", marginTop:'20px', color:'white', borderRadius:'5px',textAlign:'center' }}>Select file</label>
                            <input id="filePicker" style={{visibility:"hidden"}} type={"file"} onChange={this.onFileChange} accept=".jpg"/>

                            {this.state.loadershow === true ?
                              

                              <Loader type="spinner-cub" bgColor={'#01b3a7'} title={this.state.textloading} color={'#01b3a7'} size={100} />
                                
        
                              :
        
                              null
        
                            }

                            <div className='d-flex flex-row justify-content-center'>
                                        
                            <Button className="btn"style={{ margin:"10px", width:'150px'}} onClick={ () => this.setState({modalupdate:false})} variant="primary">Close</Button>
                            <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.updatetours(htmlPuri)} variant="success">Update</Button>
                                
                            </div>

                        </div>          
                      
                    </Modal.Body>

                  </Modal>

                  :

                  null

                  }

                  {this.state.modaldelete === true ?

                  <Modal

                  show={this.state.modaldelete}
                  onHide={ () => this.setState({modaldelete:false})}
                  dialogClassName="modal-dialog"
                  aria-labelledby="example-custom-modal-styling-title" centered
                  >
                    <Modal.Body>
                                
                    <div className="d-flex flex-row justify-content-center">
                                      
                        <div className="d-flex flex-column" style={{margin:"20px"}}>
                            
                            <div className="d-flex flex-column">

                                <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>Would you like to delete</p>
                                <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.title} &nbsp;</p>

                                <div className='d-flex justify-content-center'>
                                  <img src={process.env.PUBLIC_URL + './images/tours/' + this.state.idlistfile + '.' + this.state.extensionfile} style={{width:"150px", height:"150px"}} className="d-flex justify-content-center"/>
                                </div>
                                <form>

                                <div className='row text-white d-flex align-items-center'>
                                            
                                      <Button className="btn"style={{ margin:"20px", width:'150px'}} onClick={ () => this.setState({modaldelete:false})} variant="primary">Close</Button>
                                      <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.deletetours()} variant="danger">Delete</Button>

                                </div>
                                
                              </form>
                            </div>
                            
                        </div>

                    </div>    

                  </Modal.Body>
                  </Modal>

                  :

                  null

                  }

                  <div className='centrervue' >
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          
                          <TableCell >Images</TableCell>
                          <TableCell >Title</TableCell>
                          <TableCell >Description</TableCell>
                          <TableCell >Road map</TableCell>
                          <TableCell >Add image</TableCell>
                          <TableCell >Update</TableCell>
                          <TableCell >Delete</TableCell>
                          
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.listfile.map((row) => (
                          <TableRow key={row.idtours}>

                            <TableCell component="th" scope="row" className='verticalcenter'><img src={process.env.PUBLIC_URL + './images/tours/' + row.idtours + '.' + row.extensionimage} style={{width:"150px", height:"150px"}} className="verticalcenter"/></TableCell>
                            <TableCell >{row.titletours}</TableCell>
                            <TableCell >{row.descriptiontours}</TableCell>
                            <TableCell dangerouslySetInnerHTML={{ __html: row.roadmaptours }}></TableCell>
                            <TableCell ><button type="submit" className="btn btn-success btn-circle btn-xl" onClick={() => this.selectimagetours(row.idtours,row.titletours)}><span><AddIcon /></span></button></TableCell>
                            <TableCell ><button type="submit" className="btn btn-primary btn-circle btn-xl" onClick={() => this.showupdatetours(row.idtours,row.titletours,row.descriptiontours,row.roadmaptours,row.extensionimage)}><span><EditIcon /></span></button></TableCell>
                            <TableCell ><button type="submit" className="btn btn-danger btn-circle btn-xl" onClick={() => this.showdeletetours(row.idtours,row.titletours,row.extensionimage)}><span><DeleteIcon /></span></button></TableCell>


                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  </div> 

                  {this.state.modaladdimage === true ?

                    <Modal

                    show={this.state.modaladdimage}
                    onHide={ () => this.setState({modaladdimage:false, listimage: []})}
                    dialogClassName="modal-dialog"
                    aria-labelledby="example-custom-modal-styling-title" centered
                    >
                      <Modal.Body>

                      <div className="App">
                                              
                                <div className='d-flex flex-column centerdata'>

                                  <p style={{fontWeight:'bold'}}>{this.state.titletoursedit}</p>

                                    {this.state.imagefilename === null ?

                                      <div >
                                          No select file                    
                                      </div>

                                      :

                                      this.state.imagefilename

                                    }

                                    <label htmlFor="filePicker" style={{background:"#0d6efd", padding:"5px 10px", marginTop:'20px', color:'white', borderRadius:'5px',textAlign:'center' }}> Select file </label>
                                    <input id="filePicker" style={{visibility:"hidden"}} type={"file"} onChange={this.onFileChange} accept=".jpg"/>

                                    {this.state.loadershow === true ?
                                      

                                      <Loader type="spinner-cub" bgColor={'#01b3a7'} title={this.state.textloading} color={'#01b3a7'} size={100} />
                                        
                
                                      :
                
                                      null
                
                                    }
                                    

                                    {this.state.selectedFile === null ?

                                      null

                                      :

                                      <button onClick={this.addimagetours} className="btn btn-success">ADD FILE</button> 

                                    }
                                    
                                              
                                </div> 


                            {this.state.modaldeleteimage === true ?

                                                                     
                              <div className="d-flex flex-row justify-content-center">
                                                
                                  <div className="d-flex flex-column" style={{margin:"20px"}}>
                                      
                                      <div className="d-flex flex-column">
                                          <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>Would you like to delete?</p>
                                          <div className='d-flex justify-content-center'>
                                            <img src={process.env.PUBLIC_URL + './images/tours/gallerytours/' + this.state.idimagetours + '.' + this.state.extensionfile} style={{width:"150px", height:"150px"}} className="d-flex justify-content-center"/>
                                          </div>
                                          <form>

                                          <div className='row text-white d-flex align-items-center'>
                                                      
                                                <Button className="btn"style={{ margin:"20px", width:'150px'}} onClick={ () => this.setState({modaldeleteimage:false})} variant="primary">Close</Button>
                                                <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.deleteimagetours()} variant="danger">Delete</Button>

                                          </div>
                                          
                                        </form>
                                      </div>
                                      
                                  </div>

                              </div>    

                            :

                            null

                            }

                            <div className='centrervue' style={{height:'500px', overflow:'auto'}}>
                              <TableContainer>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      
                                      <TableCell >Images</TableCell>
                                      <TableCell >Delete</TableCell>
                                      
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {this.state.listimage.map((row) => (
                                      <TableRow key={row.idimagestours}>

                                        <TableCell component="th" scope="row" className='verticalcenter'><img src={process.env.PUBLIC_URL + './images/tours/gallerytours/' + row.idimagestours + '.' + row.extensionimage} style={{width:"150px", height:"150px"}} className="verticalcenter"/></TableCell>
                                        <TableCell ><button type="submit" className="btn btn-danger btn-circle btn-xl" onClick={() => this.showdeleteimagetours(row.idimagestours,row.extensionimage)}><span><DeleteIcon /></span></button></TableCell>


                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div> 
                        
                          </div>

                      
                      </Modal.Body>
                    </Modal>

                    :

                    null

                    }
               
                </div>
                
          );
        }
    
}

export default Toursdata;
