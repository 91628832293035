
import React,  {Alert,Component} from 'react';
import {useEffect} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { green, purple } from '@material-ui/core/colors';
import Autocomplete from '@material-ui/lab/Autocomplete';
import queryString from 'query-string';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import '../App.css';
import { Nav,Navbar,Button,Form,Row,Modal,Col} from 'react-bootstrap';
import Loader from "react-js-loader";


class Acceuildata extends Component{
    
    constructor(props) {
    super(props);
      this.state = {
  
        selectedFile: null,
        selectedOption: null,
        listuser: [],
        modalupdate: false,
        modaldelete: false,
        listfile : [],
        idlistfile:0,
        title:'',
        description:'',
        loadershow:false,
        imagefilename:'',
        tokenvalue : '',
        
      };

  }

  componentWillMount() {

    document.title = "Accueil Data";

    let activeadmin = sessionStorage.getItem('usertype');
  
    if(activeadmin === null || activeadmin === 'Undefined'){

      this.setState({ tokenexist: false})
      return

    }else{

      this.setState({ tokenvalue: activeadmin})
      this.reloaddata();
      
    }

  }

  reloaddata () {

    if(this.state.tokenvalue !== ''){

          this.setState({ reload: true})
          
    }

  }
    
  async componentDidMount() { 

    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });

    const response = await fetch('https://exotic-madagascar.net/wbs/accueil/allaccueil.php');
    const data = await response.json();
    this.setState({ listfile: data})
  
  }

  onFileChange = event => { 
    // Update the state 
    this.setState({
      selectedFile: event.target.files[0],
      imagefilename : event.target.files[0].name,
     }); 

  }; 
   
  // On file upload (click the upload button) 
  onFileUpload = () => { 

    if(this.state.selectedFile === null || this.state.selectedFile === 'null'){

      this.setState({
        loadershow: false,
        textloading:'Please select a file.'
      });
      
      return;
    
    }


    if(this.state.title === '' ){

      this.setState({
        loadershow: false,
        textloading:'Please put make a title.'
      });
      
      return;
    
    }

    if(this.state.description === '' ){

      this.setState({
        loadershow: false,
        textloading:'Please put make a description.'
      });
      
      return;
    
    }

    this.setState({
      loadershow: true,
      textloading:'Uploading file ...'
    });

    // Create an object of formData 
    const formData = new FormData(); 
   
    // Update the formData object 
    formData.append( 
      "image", 
      this.state.selectedFile, 
      this.state.selectedFile.name,
    ); 

    formData.append("title",this.state.title);
    formData.append("description",this.state.description);

   
    // Details of the uploaded file 
   // console.log(this.state.selectedFile); 

   
    // Request made to the backend api 
    // Send formData object 
    axios.post("https://exotic-madagascar.net/wbs/accueil/addfile.php", formData)
    .then(res => {

      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
  
      //alert(JSON.stringify(res.data));
      this.setState({ listfile : res.data});

      this.loaderdesactive();

  })

}

loaderdesactive(){

  this.setState({
    loadershow: false,
    selectedFile:'',
    idlistfile: '',
    title: '',
    description: '',
    extensionfile:'',
    imagefilename:'',
    textloading:''
  });
  
}

showupdatefile(idfile,title,description,extensionfile){

  this.setState({
    idlistfile: idfile,
    title: title,
    description: description,
    extensionfile : extensionfile,
    modalupdate: true
  })

}

updatefile(){

  if(this.state.selectedFile === null){

    fetch(`https://exotic-madagascar.net/wbs/accueil/updatefile.php`,{                  
        
      method:'post',
      headers: {'Content-Type':'application/x-www-form-urlencoded'},
      body: queryString.stringify({
        idfile: this.state.idlistfile,
        title : this.state.title,
        description:this.state.description,
        extensionfile:this.state.extensionfile,
      })

      })

      .then(response => response.json())
      .then(data => {

      if(JSON.stringify(data).replace(/\"/g, "") === 'Error'){

        this.setState({ errorform: true, errormessage: 'Error update file.' });
        return;
      
      }

        this.setState({
          idlistfile: '',
          title: '',
          description: '',
          extensionfile:'',
          modalupdate: false
        })

        this.refreshdata();

      })
      .catch(error => {
        this.setState({ errormessage: error});
      });  

  }else{

    const formData = new FormData(); 
   
    formData.append( 
      "image", 
      this.state.selectedFile, 
      this.state.selectedFile.name,
    ); 

    formData.append("idfile",this.state.idlistfile);
    formData.append("extension",this.state.extensionfile);
   
    axios.post("https://exotic-madagascar.net/wbs/accueil/removeaddfile.php", formData)
    .then(res => {

      fetch(`https://exotic-madagascar.net/wbs/accueil/updatefile.php`,{                  
        
        method:'post',
        headers: {'Content-Type':'application/x-www-form-urlencoded'},
        body: queryString.stringify({
          idfile: this.state.idlistfile,
          title : this.state.title,
          description:this.state.description,
          extensionfile:this.state.extensionfile,
        })
  
        })
  
        .then(response => response.json())
        .then(data => {
  
        if(JSON.stringify(data).replace(/\"/g, "") === 'Error'){
  
          this.setState({ errorform: true, errormessage: 'Error update file.' });
          return;
        
        }
  
          this.setState({
            idlistfile: '',
            title: '',
            description: '',
            extensionfile:'',
            modalupdate: false
          })
  
          this.refreshdata();
  
        })
        .catch(error => {
          this.setState({ errormessage: error});
        });  

  })


  }

}

showdeletefile(idfile,title,description,extensionfile){
  
  this.setState({
    idlistfile: idfile,
    title: title,
    description: description,
    extensionfile : extensionfile,
    modaldelete: true
  })

}

deletefile(){

  fetch(`https://exotic-madagascar.net/wbs/accueil/deletefile.php`,{                  
        
    method:'post',
    headers: {'Content-Type':'application/x-www-form-urlencoded'},
    body: queryString.stringify({
      idfile: this.state.idlistfile,
      extension : this.state.extensionfile,
    })

    })

    .then(response => response.json())
    .then(data => {

    if(JSON.stringify(data).replace(/\"/g, "") === 'Error'){

      this.setState({ errorform: true, errormessage: 'Error delete file.' });
      return;

    }

    this.setState({
      idlistfile: 0,
      title: '',
      description: '',
      extensionfile : '',
      selectedFile:'',
      modaldelete: false
    })

    this.refreshdata();

    })
    .catch(error => {
      this.setState({ tokenuser: error});
    });  

}

async refreshdata(){

  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });

  const response = await fetch('https://exotic-madagascar.net/wbs/accueil/allaccueil.php');
  const data = await response.json();
  this.setState({
    listfile: data,
    idlistfile: 0,
    title: '',
    description: '',
    extensionfile:'',
    selectedFile:'',
  })

}
    
  render() {

          return (
              
                <div className="App" style={{marginTop:'100px'}}>
                  
                  {this.state.tokenvalue === '' ?
                            

                            <Redirect push to="/"/>
                              

                            :

                            null

                    }

                      <div className='d-flex flex-column centerdata'>

                          <label>Title :</label>
                          <input style={{width:'40%', margin:'10px'}}type="text" className="form-control" value={this.state.title} onChange={(e) => this.setState({ title: e.target.value})} />
                        
                          <label>Description :</label>
                          <textarea type="textarea" rows={2} style={{width:'40%', margin:'10px'}} className="form-control" value={this.state.description} onChange={(e) => this.setState({ description: e.target.value})} />

                          {this.state.imagefilename === null ?

                            <div >
                                No select file                    
                            </div>

                            :

                            this.state.imagefilename

                          }

                          <label htmlFor="filePicker" style={{background:"#0d6efd", padding:"5px 10px", marginTop:'20px', color:'white', borderRadius:'5px',textAlign:'center' }}> Select file </label>
                          <input id="filePicker" style={{visibility:"hidden"}} type={"file"} onChange={this.onFileChange} accept=".jpg"/>

                          {this.state.loadershow === true ?
                            

                            <Loader type="spinner-cub" bgColor={'#01b3a7'} title={this.state.textloading} color={'#01b3a7'} size={100} />
                              
      
                            :
      
                            null
      
                          }
                          

                          {/*<input style={{margin:'10px'}} type="file" onChange={this.onFileChange} accept=".jpg" />*/}

                          {this.state.selectedFile === null ?

                            null

                            :

                            <button onClick={this.onFileUpload} className="btn btn-success">ADD FILE</button> 

                          }
                          
                                    
                      </div> 

                  
                  {this.state.modalupdate === true ?

                  <Modal
                  
                  show={this.state.modalupdate}
                  onHide={ () => this.setState({modalupdate:false})}
                  dialogClassName="modal-dialog"
                  aria-labelledby="example-custom-modal-styling-title" centered
                  >
                    <Modal.Body>
                      <div className="d-flex flex-column justify-content-center">

                          <label style={{marginLeft:'5%'}}>Title :</label>
                          <input style={{width:'90%', margin:'5%'}} type="text" className="form-control" value={this.state.title} onChange={(e) => this.setState({ title: e.target.value})} />
                  
                          <label style={{marginLeft:'5%'}}>Description :</label>
                          <textarea type="textarea" rows={2} style={{width:'90%', margin:'5%'}} className="form-control" value={this.state.description} onChange={(e) => this.setState({ description: e.target.value})} />
                          
                          <div className='d-flex justify-content-center'>
                            <img src={process.env.PUBLIC_URL + './images/accueil/' + this.state.idlistfile + '.' + this.state.extensionfile} style={{width:"150px", height:"150px"}} className="d-flex justify-content-center"/>
                          </div>

                          <div className='d-flex justify-content-center'>
                            <input style={{margin:'10px'}} type="file" onChange={this.onFileChange} accept=".jpg" />
                          </div>
                           
                          <div className='d-flex flex-row justify-content-center'>
                                      
                                <Button className="btn"style={{ margin:"10px", width:'150px'}} onClick={ () => this.setState({modalupdate:false})} variant="primary">Close</Button>
                                <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.updatefile()} variant="success">Update</Button>

                          </div>

                      </div>          
                    
                  </Modal.Body>
                  </Modal>

                  :

                  null

                  }

                  {this.state.modaldelete === true ?

                  <Modal

                  show={this.state.modaldelete}
                  onHide={ () => this.setState({modaldelete:false})}
                  dialogClassName="modal-dialog"
                  aria-labelledby="example-custom-modal-styling-title" centered
                  >
                    <Modal.Body>
                                
                    <div className="d-flex flex-row justify-content-center">
                                      
                        <div className="d-flex flex-column" style={{margin:"20px"}}>
                            
                            <div className="d-flex flex-column">
                                <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>Would you like to delete</p>
                                <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.title} &nbsp;</p>
                                <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.description} ?&nbsp;</p>
                                <div className='d-flex justify-content-center'>
                                  <img src={process.env.PUBLIC_URL + './images/accueil/' + this.state.idlistfile + '.' + this.state.extensionfile} style={{width:"150px", height:"150px"}} className="d-flex justify-content-center"/>
                                </div>
                                <form>

                                <div className='row text-white d-flex align-items-center'>
                                            
                                      <Button className="btn"style={{ margin:"20px", width:'150px'}} onClick={ () => this.setState({modaldelete:false})} variant="primary">Close</Button>
                                      <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.deletefile()} variant="danger">Delete</Button>

                                </div>
                                
                              </form>
                            </div>
                            
                        </div>

                    </div>    

                  </Modal.Body>
                  </Modal>

                  :

                  null

                  }

                  <div className='centrervue' >
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          
                          <TableCell >Images</TableCell>
                          <TableCell >Title</TableCell>
                          <TableCell >Description</TableCell>
                          <TableCell >Update</TableCell>
                          <TableCell >Delete</TableCell>
                          
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.listfile.map((row) => (
                          <TableRow key={row.idaccueil}>

                            <TableCell component="th" scope="row" className='verticalcenter'><img src={process.env.PUBLIC_URL + './images/accueil/' + row.idaccueil + '.' + row.extensionimage} style={{width:"150px", height:"150px"}} className="verticalcenter"/></TableCell>
                            <TableCell >{row.titre}</TableCell>
                            <TableCell >{row.description}</TableCell>
                            <TableCell ><button type="submit" className="btn btn-primary btn-circle btn-xl" onClick={() => this.showupdatefile(row.idaccueil,row.titre,row.description,row.extensionimage)}><span><EditIcon /></span></button></TableCell>
                            <TableCell ><button type="submit" className="btn btn-danger btn-circle btn-xl" onClick={() => this.showdeletefile(row.idaccueil,row.titre,row.description,row.extensionimage)}><span><DeleteIcon /></span></button></TableCell>


                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  </div> 
               
                </div>
                
          );
        }
    
}

export default Acceuildata;
