import '../App.css';
import React,  {Component} from 'react';
import { Nav,Navbar,Button,Form,Container,Row,Col} from 'react-bootstrap';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Redirect  } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal'
import Toast from 'react-bootstrap/Toast'
import Card from 'react-bootstrap/Card'
import Bounce from 'react-reveal/Bounce';
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';
import axios from 'axios';
import { BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import Carousel from 'react-bootstrap/Carousel';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import MailIcon from '@material-ui/icons/Mail';
import CallIcon from '@material-ui/icons/Call';
import HouseIcon from '@material-ui/icons/House';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { green, purple, red } from '@material-ui/core/colors';
//import Autocomplete from '@material-ui/lab/Autocomplete';
import queryString from 'query-string';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Loader from "react-js-loader";

class Login extends Component{
    
    constructor(props) {
    super(props);
    this.state = {
      mail: '',
      motdepasse: '',
      errorform: false,
      errormessage:'',
      signin : false,
      tokenexist: true,
      tokenvalue: '',
      reload: false,
      loadershow : false,
      textloading:'',
    };

        
  }
    
    componentDidMount() {

      document.title = "Login";

             
    };

    componentWillMount() {

      sessionStorage.setItem(
                
        'active', false
        
      );

      this.setState({
    
        textloading:'Loading data ...',
      
      });

      //this.refreshdata();

    }

  

    refreshdata () {

      if (this.state.reload === false){

        window.location.reload(false);
        this.setState({ reload: true})


      }
            

    }

    verifyuser () {

        if (this.state.mail == "" ) {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

            if (!pattern.test(this.state.mail)) {

              this.setState({ errorform: true, errormessage: 'Mail invalid.' });
                
                return;

            }
            
        }
        
        if (this.state.motdepasse == "") {

          this.setState({ errorform: true, errormessage: 'Password error.' });
                            
                return;

        }

        this.setState({ loadershow: true });

        this.connexionuser()

  }; 

  connexionuser(){

          fetch(`https://exotic-madagascar.net/wbs/users/connexion.php`,{                  
            
            method:'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: queryString.stringify({
              mailutilisateur:this.state.mail,
              motdepasseutilisateur:this.state.motdepasse,
            })

      })

      .then(response => response.json())
      .then(data => {

      if(JSON.stringify(data).replace(/\"/g, "") === 'Usernotfound'){

        this.setState({ errorform: true, errormessage: 'User not found.' });
        return;

      }

      if(JSON.stringify(data).replace(/\"/g, "") === 'Errorpassword'){

        this.setState({ errorform: true, errormessage: 'Password invalid.' });
        return;

      }

      if(JSON.stringify(data).replace(/\"/g, "") === 'Errodb'){

        this.setState({ errorform: true, errormessage: 'Site maintenance in progress, please try again later.' });
        return;
        
      }

      if(JSON.stringify(data).replace(/\"/g, "") === 'Connected'){

        this.setState({ loadershow: true});
      
        sessionStorage.setItem( 
          'active', 'yes'
        );

        sessionStorage.setItem(
          'reloaded', false
        );

        sessionStorage.setItem(
          'refreshdata', 'no'
        );

        this.setState({

          loadershow: false,
          errorform: false,
          errormessage:'',             
          signin : true,

        });              
        
      }

      

      })
      .catch(error => {
          this.setState({ tokenuser: error});
      });  

  }
   
  render() {

          return (
              
                <div style={{marginTop:'90px'}}>
                  
                  <Fade Bottom cascade duration={3000}>
                  <div className="d-flex justify-content-center">
                      <div className="d-flex flex-column" style={{width:'500px'}}>

                        <div className="form-group" style={{margin:'5px'}}>
                          <label>Email *</label>
                          <input type="email" className="form-control" aria-describedby="emailHelp" value={this.state.mail} onChange={(e) => this.setState({ mail: e.target.value})} />
                        </div>

                        <div className="form-group" style={{margin:'5px'}}>
                          <label>Password *</label>
                          <input type="password" className="form-control" value={this.state.motdepasse} onChange={(e) => this.setState({ motdepasse: e.target.value})} />
                        </div>

                        {this.state.errorform == true ? 

                          <Alert severity="error">
                            <AlertTitle>Error</AlertTitle>
                            {this.state.errormessage}
                          </Alert>

                          :

                          null

                        }

                        {this.state.signin == true ?
                            

                            <Redirect push to="/Connexion"/>
                              

                            :

                            null

                        }

                        {this.state.loadershow == true ?
                            

                            <Loader type="spinner-cub" bgColor={'#01b3a7'} title={this.state.textloading} color={'#01b3a7'} size={100} />
                              

                            :

                            null

                        }

                        <div className="d-flex justify-content-center">
                          <Button className="btn" style={{ marginTop:'10px', width:'150px'}} onClick={() => this.verifyuser()} >Sign in</Button>
                        </div> 

                                           
         
                        </div>

                      </div> 

                      </Fade>  

                </div>

          );

        }
    
}

export default Login;
